import React from "react";
import { ThemeContext } from "../layouts";
import PropTypes from "prop-types";
import Article from "../components/Article";

const NotFoundPage = props => {
  return (

    <div style={{padding:90}}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  );
};

export default NotFoundPage;
